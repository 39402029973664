import reduce, { defaultState, errorState, pendingState, successState } from '../../helpers/redux';
import { TIERS_FETCH, TIERS_SIDEBAR_TOGGLE } from '../../constants/ActionTypes';
import { TIER_IDS } from '../../constants/Tiers';

export const initialState = {
	...defaultState,
	collection: [],
	savodCollection: [],
	userSelectedTier: null,
	sidebarOpen: false,
};

const actionsMap = {
	[TIERS_FETCH + '_PENDING']: (state) => ({ ...state, ...pendingState }),
	[TIERS_FETCH + '_ERROR']: (state) => ({ ...state, ...errorState }),
	[TIERS_FETCH + '_SUCCESS']: (state, { payload }) => {
		const tiersWithouSavod = payload.data.tiers.filter(
			({ id }) =>
				id !== TIER_IDS.PREMIUM_WITH_ADS &&
				id !== TIER_IDS.STANDARD_WITH_ADS &&
				id !== TIER_IDS.FREE
		);
		return {
			...successState,
			collection: tiersWithouSavod,
			savodCollection: payload.data.tiers,
		};
	},
	[TIERS_SIDEBAR_TOGGLE]: (state, { payload }) => ({
		...state,
		sidebarOpen: payload.isOpen,
	}),
};

export default reduce(initialState, actionsMap);
